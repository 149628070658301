<template>
  <form class="bg-white rounded-8" @submit.prevent="handleSubmit">
    <div class="d-flex align-items-center justify px-24 py-16 border-bottom">
      <h2 class="fs-20 me-auto mb-0">User</h2>
      <button
        type="submit"
        class="btn btn-primary"
        :class="{ 'is-loading': isSubmitting }"
        :disabled="isSubmitting"
      >
        Simpan
      </button>
    </div>

    <div class="p-24">
      <InputField
        label="Nama"
        required
        v-model="input.name"
        :error="errors.name"
      />
      <div class="row">
        <div class="col-sm-6">
          <InputField
            class="mt-16"
            label="Email"
            required
            v-model="input.email"
            :attrs="{ type: 'email' }"
            :error="errors.email"
          />
        </div>
        <div class="col-sm-6">
          <InputField
            class="mt-16"
            label="Password"
            required
            v-model="input.password"
            :attrs="{ type: 'password' }"
            :error="errors.password"
          />
        </div>
      </div>
      <RoleField
        class="mt-16"
        label="Role"
        required
        v-model="input.roles"
        :error="errors.roles"
      />
    </div>

    <div class="p-24 border-top" v-if="isTeacher">
      <h3 class="text-muted fs-11 ls-02 text-uppercase">Data Guru</h3>

      <div class="row mt-8">
        <div class="col-sm-6">
          <InputField
            class="mt-16"
            label="NIP"
            v-model="input.teacher.nip"
            :error="errors['teacher.nip']"
          />
        </div>
        <div class="col-sm-6">
          <InputField
            class="mt-16"
            label="NUPTK"
            v-model="input.teacher.nuptk"
            :error="errors['teacher.nuptk']"
          />
        </div>
      </div>
    </div>

    <div class="p-24 border-top" v-if="isStudent">
      <h3 class="text-muted fs-11 ls-02 text-uppercase">Data Siswa</h3>

      <div class="row mt-8">
        <div class="col-sm-6">
          <InputField
            class="mt-16"
            label="NISN"
            required
            v-model="input.student.nisn"
            :error="errors['student.nisn']"
          />
        </div>
        <div class="col-sm-6">
          <InputField
            class="mt-16"
            label="NIS"
            required
            v-model="input.student.nis"
            :error="errors['student.nis']"
          />
        </div>
      </div>
      <GradeSelectField
        class="mt-16"
        label="Kelas"
        v-model="input.student.grade_id"
        :error="errors['student.grade_id']"
      />
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import userRepository from '../../../api/repositories/userRepository';
import Role from '../../../store/models/Role';

import GradeSelectField from '../../grade/GradeSelectField.vue';
import RoleField from '../../role/RoleField.vue';
import InputField from '../../form/InputField.vue';

export default {
  components: { GradeSelectField, RoleField, InputField },

  props: ['user'],

  data() {
    return { errors: {}, input: this.getInitialInput(), isSubmitting: false };
  },

  computed: {
    isStudent() {
      const role = Role.query().where('code', 'student').first();
      return role && this.input.roles.includes(role.code);
    },

    isTeacher() {
      const role = Role.query().where('code', 'teacher').first();
      return role && this.input.roles.includes(role.code);
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;

        if (this.input.id) {
          await userRepository.update(this.input);
          this.$alert.success('User berhasil diubah');
        } else {
          await userRepository.store(this.input);
          this.$alert.success('User berhasil dibuat', {
            onOK: () => this.$router.push('/admin/user'),
          });
        }

        this.$store.commit('users/invalidate');
      } catch (error) {
        this.errors = get(error, 'response.data.error.errors', {});
        this.$alert.requestError(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    getInitialInput() {
      const input = this.user ? this.user.$toJson() : {};
      input.roles = (input.roles || []).map(({ code }) => code);
      input.student = input.student || {};
      input.teacher = input.teacher || {};

      return input;
    },
  },

  watch: {
    user() {
      this.input = this.getInitialInput();
    },
  },
};
</script>
